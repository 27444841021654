<style lang="scss">
@import "~@/assets/css/var";

.static-data {
    display: inline-block;
    padding-left: 15px;
    font-size: 14px;
    cursor: pointer;
    min-width: unset;
    color: #777;

    &:hover {
        text-decoration: underline;

        .red {
            text-decoration: underline;
        }
    }

    .red {
        display: inline;
        color: $red;
    }
}

.make-table {
    .el-pagination {
        display: flex;

        .page-right {
            text-align: right;

            // flex: 1;
            .static-data {
                text-decoration: unset;
                cursor: default;
            }

            .red {
                text-decoration: unset;
                padding-right: 30px;

                &:last-child {
                    padding: 0;
                }
            }
        }
    }
}

.static-dialog {
    height: 400px;
    overflow-y: auto;

    .el-badge {
        margin: 0 30px 10px 0;
    }

    .el-tag {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__title {
        margin-bottom: 10px;
    }
}

.edit-drawer_assist {
    padding-left: 15px;
    color: $red;
    font-size: 14px !important;
}
</style>
<template>
    <div class="common-table">
        <div class="common-table__title">
            <el-select v-model="val" value-key="page" filter placeholder="请选择数据库" size="small" @change="databaseChange">
                <el-option v-for="item in selectList" :key="item.page" :value="item" :label="item.value"></el-option>
            </el-select>
            <el-button v-if="loaded" style="margin-left: 20px;" icon="el-icon-circle-plus" type="primary"
                @click="add">新增</el-button>

            <span v-show="staticData.countNumber" class="static-data" @click="dialog.show = true">
                现有数据量：<span class="red">{{ staticData.countNumber }}</span>，需求：<span class="red">无限</span></span>

            <ts-table-operate v-if="searchParam.name" :name="searchParam.name" :table-name="searchParam.tableName"
                @searchShowLeft="searchShowLeft" :cols="searchParam.cols" @handleFilter="handleFilter"
                @viewChange="viewChange">
                <el-select v-model="status" placeholder="请选择审核状态" style="width: 150px" @change="statusChange">
                    <el-option value="" label="不限"></el-option>
                    <el-option value="0" label="创建待审核"></el-option>
                    <el-option value="1" label="审核通过"></el-option>
                    <el-option value="2" label="审核不通过"></el-option>
                </el-select>
            </ts-table-operate>
        </div>

        <ts-dialog :show.sync="dialog.show" title="统计数据" hide-footer width="768px">
            <div class="static-dialog">
                <div class="static-dialog__item" v-for="item in dialog.cols" :key="item.field">
                    <p class="static-dialog__title">{{ item.title }}：</p>

                    <el-badge v-for="row in staticData[item.field]" :key="row[item.showField]" :value="row.value"
                        v-show="row[item.showField]">
                        <el-tag size="normal" :title="row[item.showField]">
                            {{ row[item.showField] }}</el-tag>
                    </el-badge>
                </div>
            </div>
        </ts-dialog>

        <component ref="page" v-if="loaded" :visit-id="visitId" :is="val.component" :title="val.value"
            @pageChange="pageChange" :searchParam="searchParam" @passStatic="showStatic"></component>
    </div>
</template>

<script>
function loadComponent(url) {
    return () => import(`./${url}`)
}
export default {
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',

            loaded: false,
            val: {},
            selectList: [
                {
                    key: 'makeInstruction',
                    value: '说明书',
                    component: loadComponent('InstructionBooks'),
                    page: 'sys/duInstructionBooks/queryDuInstructionBooksPage',
                    add: 'sys/duInstructionBooks/insertDuInstructionBooks',
                    modify: 'sys/duInstructionBooks/updateDuInstructionBooks',
                    del: 'sys/duInstructionBooks/deleteById',
                },
                {
                    key: 'makeLiterature',
                    value: '产品文献',
                    component: loadComponent('ArticleLiterature'),
                    page: 'sys/baseArticleLiterature/queryBaseArticleLiteraturePage',
                    add: 'sys/baseArticleLiterature/insertBaseArticleLiterature',
                    modify: 'sys/baseArticleLiterature/updateBaseArticleLiterature',
                    del: 'sys/baseArticleLiterature/deleteById',
                },
                {
                    key: 'makeArticleGuide',
                    value: '临床指南',
                    component: loadComponent('ArticleGuide'),
                    page: 'sys/baseArticleGuide/queryBaseArticleGuidePage',
                    add: 'sys/baseArticleGuide/insertBaseArticleGuide',
                    modify: 'sys/baseArticleGuide/updateBaseArticleGuide',
                    del: 'sys/baseArticleGuide/deleteById',
                    table: 'base_article_guide'
                },
                {
                    key: 'makeArticlePath',
                    value: '临床路径',
                    component: loadComponent('ArticlePath'),
                    page: 'sys/baseArticlePath/queryBaseArticlePathPage',
                    add: 'sys/baseArticlePath/insertBaseArticlePath',
                    modify: 'sys/baseArticlePath/updateBaseArticlePath',
                    del: 'sys/baseArticlePath/deleteById',
                    table: 'base_article_path'
                },
                {
                    key: 'makeArticleNews',
                    value: '产品新闻',
                    component: loadComponent('ArticleNews'),
                    page: 'sys/baseArticleNews/queryBaseArticleNewsPage',
                    add: 'sys/baseArticleNews/insertBaseArticleNews',
                    modify: 'sys/baseArticleNews/updateBaseArticleNews',
                    del: 'sys/baseArticleNews/deleteById',
                    table: 'base_article_news'
                },
                {
                    key: 'makeArticleCompNews',
                    value: '企业新闻',
                    component: loadComponent('ArticleCompNews'),
                    page: 'sys/baseCompNews/queryBaseCompNewsPage',
                    add: 'sys/baseCompNews/insertBaseCompNews',
                    modify: 'sys/baseCompNews/updateBaseCompNews',
                    del: 'sys/baseCompNews/deleteById',
                    table: 'base_comp_news'
                },
            ],

            status: '',

            //筛选的参数
            searchParam: {
                name: '',
                cols: [],
                tableName: '',
            },
            staticData: {},

            dialog: {
                show: false,
                cols: [],
            },
            isSearchLeft: false,
        }
    },
    watch: {
        val: {
            immediate: true,
            handler() {
                if (this.val.key) {
                    this.visitId = new Date().getTime()
                    this.pageId = this.val.key
                    this.pageName = this.val.value

                    this.$help.socket.send(this)
                }
            }
        },
        '$route': function () {
            this.val = this.selectList[this.$route.query.type || 0]
            this.databaseChange()
        }
    },
    created() {
        this.val = this.selectList[this.$route.query.type || 0]
        this.databaseChange()
    },
    methods: {
        searchShowLeft() {
            this.$refs.page.searchShowLeft()
        },
        databaseChange() {
            this.loaded = false
            this.$nextTick(() => {
                this.loaded = true
            })
            this.staticData = {}
        },
        add() {
            this.$refs.page.add()
        },

        pageChange(param) {
            this.searchParam.name = ''
            this.$nextTick(() => {
                this.searchParam.name = param.tableName + 'Make'
                this.searchParam.tableName = param.tableName
                this.searchParam.cols = param.cols.map(item => {
                    return {
                        id: item.key,
                        fieldName: item.searchKey || item.key,
                        name: item.value,
                        listQueryView: item.search ? '是' : '否',
                        listQueryModel: item.queryType || this.$variable.searchType.LIKE
                    }
                })
            })
        },

        showStatic(form) {
            this.staticData = form.data
            this.dialog.cols = form.cols
        },

        handleFilter(cols) {
            this.$refs.page.searchCols = cols
            this.$refs.page.init()
        },

        statusChange() {
            this.$refs.page.init()
        },

        viewChange() { },
    }
}
</script>